import '../styles/globals.css';
import '../styles/expander.css';
import '../styles/modal.css';

// import 'nextra-theme-docs/style.css';
import 'antd/dist/antd.css';

import '@phpcreation/frontend-components-react/build.css';
import '@phpcreation/frontend-components-crud-react/build.css';

import type { AppProps } from 'next/app';
import React from 'react';
import { AppContextProvider } from '@phpcreation/frontend-components-react';

import en from '@/utils/locales/en/common.json';
import fr from '@/utils/locales/fr/common.json';
import bi from '@/utils/locales/bi/common.json';

const messages = {
  en,
  fr,
  bi,
};

// For testing components
const endpoints = {
  crud: 'https://demo.phpreaction.com/open-api/v3',
  auth: 'https://demo.phpreaction.com/authentication/open-api/v3/token',
  status: 'https://demo.phpreaction.com/open-api/v3/status',
  documentation: 'https://demo.phpreaction.com/open-api/v3',
};

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AppContextProvider endpoints={endpoints} extraMessages={messages}>
      <Component {...pageProps} />
    </AppContextProvider>
  );
}
// export default MyApp;

export default MyApp;
